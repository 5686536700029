<template>
    <div class="page_container" :class="{'straight_length': borderPosition}">
        <div class="page_container-wrapper">
            <div class="page_sidebar">
                <div class="page_sidebar-filters">
                    <b-form-group v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                                id="radio-group-1"
                                v-model="selectedFilter"
                                :options="filterOptions"
                                :aria-describedby="ariaDescribedby"
                                name="radio-options"
                        ></b-form-radio-group>
                    </b-form-group>
                </div>
                <div class="page_sidebar-users">
                    <b-dropdown class="user_item" dropright v-for="user in filteredLeads" @click="setEmail(user)">
                        <template #button-content>
                            <div @click="setEmail(user)" class="d-flex">
                                <div class="user_item-avatar"></div>
                                <div class="user_item-content">
                                    <div class="user_item-info">
                                        <div class="user_item-info_name">{{user.name}}</div>
<!--                                        <div class="user_item-info_time">12.00</div>-->
                                    </div>
<!--                                    <div class="user_item-message">hope you've had a great day so far !ju...</div>-->

                                </div>
                            </div>
                        </template>
                        <b-dropdown-item v-for="item in userChats">
                            <div @click="setMessages(item)">{{item.subject || '_'}}</div>
                        </b-dropdown-item>
                    </b-dropdown>
                    <!--                    <div class="user_item" v-for="user in filteredLeads" @click="setEmail(user)">-->
                    <!--                        <div class="user_item-avatar"></div>-->
                    <!--                        <div class="user_item-content">-->
                    <!--                            <div class="user_item-info">-->
                    <!--                                <div class="user_item-info_name">{{user.name}}</div>-->
                    <!--                                <div class="user_item-info_time">12.00</div>-->
                    <!--                            </div>-->
                    <!--                            <div class="user_item-message">hope you've had a great day so far !ju...</div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->

                </div>
            </div>
            <div class="page_content">
                <div class="page_head">
                    <div class="page_head-conversation">
                        <div class="page_head-conversation_title">Conversation with</div>
                        <div class="page_head-conversation_name">{{selectedUser.name}}</div>
                    </div>
                    <div class="page_head-dropdown">
                        <b-button class="auth_btn" @click="getRedirectUrl()">Authorization</b-button>
                        <div
                            class="page_head-dropdown_view"
                            @click="viewLeadInfo()"
                            v-if="selectedUser.id"
                        >View lead info</div>
<!--                        <div class="select_arrow">-->
<!--                            <b-form-select v-model="selectedUser.template_id">-->
<!--                                <template #first>-->
<!--                                    <b-form-select-option :value="null" disabled>Choose Template</b-form-select-option>-->
<!--                                </template>-->
<!--                                <option-->
<!--                                        v-for="(option, id) in allTemplates"-->
<!--                                        :key="id"-->
<!--                                        :value="option.id"-->
<!--                                        :title="option.name || null"-->
<!--                                >-->
<!--                                    {{option.name}}-->
<!--                                </option>-->
<!--                            </b-form-select>-->
<!--                            <div class="arrow_down"></div>-->
<!--                        </div>-->
                    </div>
                </div>
                <!--        Messages          -->
                <div class="page_text-content">
                    <div class="overflow_container" id="overflow_container">
                        <div class="message_item" :class="{'right_message': item.labelIds[0] !== 'SENT'}" v-for="item in showMessages">
                            <div class="message_text">
                                <div class="message_user-info" v-if="item.labelIds[0] !== 'SENT'">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-person-fill" viewBox="0 0 16 16">
                                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                    </svg>
                                    {{selectedUser.name}}
                                </div>
                                <div class="message_user-info" v-if="item.labelIds[0] == 'SENT'">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 20.258 16.181">
                                        <g id="Group_11087" data-name="Group 11087" transform="translate(-521.666 -114)">
                                            <g id="Group_437" data-name="Group 437" transform="translate(-1.525 -1654.685)">
                                                <path id="Path_286" data-name="Path 286" d="M523.222,1772.07c-.014.174-.026.25-.026.326,0,3.13-.014,6.26,0,9.389a3.007,3.007,0,0,0,3.149,3.076q6.947.011,13.894,0a3.028,3.028,0,0,0,3.2-3.22q.009-4.552,0-9.1c0-.132-.021-.265-.037-.463-.222.128-.383.22-.543.313-2.754,1.609-5.515,3.208-8.258,4.835a2.311,2.311,0,0,1-2.578.005c-2.755-1.639-5.528-3.247-8.294-4.867C523.589,1772.275,523.44,1772.193,523.222,1772.07Zm.53-2.079a1.784,1.784,0,0,0,.166.128q4.576,2.685,9.161,5.355a.7.7,0,0,0,.579-.046q4.465-2.594,8.912-5.218c.1-.06.194-.138.3-.219a3.118,3.118,0,0,0-2.678-1.306c-4.584.011-9.168,0-13.752.006a4.589,4.589,0,0,0-.568.021A2.927,2.927,0,0,0,523.752,1769.991Z" fill="#409edc"/>
                                                <path id="Path_287" data-name="Path 287" d="M523.222,1804.375c.218.123.367.2.514.29,2.766,1.62,5.539,3.229,8.294,4.867a2.311,2.311,0,0,0,2.578-.005c2.743-1.627,5.5-3.226,8.258-4.835.16-.094.322-.186.543-.313.016.2.037.331.037.463q0,4.553,0,9.1a3.028,3.028,0,0,1-3.2,3.219q-6.947.008-13.894,0a3.007,3.007,0,0,1-3.149-3.076c-.019-3.13-.006-6.26,0-9.389C523.2,1804.625,523.208,1804.549,523.222,1804.375Z" transform="translate(0 -32.305)" fill="#409edc"/>
                                                <path id="Path_288" data-name="Path 288" d="M529.108,1769.991a2.927,2.927,0,0,1,2.124-1.28,4.591,4.591,0,0,1,.568-.021c4.584,0,9.168.006,13.752-.006a3.119,3.119,0,0,1,2.678,1.306c-.111.08-.2.159-.3.219q-4.452,2.615-8.912,5.218a.7.7,0,0,1-.579.046q-4.592-2.659-9.161-5.355A1.776,1.776,0,0,1,529.108,1769.991Z" transform="translate(-5.356)" fill="#409edc"/>
                                            </g>
                                        </g>
                                    </svg>
                                    {{userData.first_name}} {{userData.last_name}}
                                </div>
                                <div>{{getSnippet(item.snippet)}}</div>
<!--                                <div>{{item.snippet}}</div>-->
                            </div>
                            <div class="message_date">{{getDate(item.internalDate)}}</div>
                        </div>
                    </div>
                    <div class="send_message-container">
                        <div>
                            <b-form-input
                                    placeholder="Subject"
                                    v-model="selectedUser.subject"
                                    type="text"
                                    trim
                            ></b-form-input>
                        </div>
                        <b-form-textarea
                                v-model="selectedUser.message"
                                placeholder="Type your message here .."
                                rows="4"
                                max-rows="6"
                        ></b-form-textarea>
                        <div class="send_message-btn_container">
<!--                            <div class="select_arrow">-->
<!--                                <b-form-select v-model="selectedUser.template_id">-->
<!--                                    <template #first>-->
<!--                                        <b-form-select-option :value="null" disabled>Choose Template-->
<!--                                        </b-form-select-option>-->
<!--                                    </template>-->
<!--                                    <option-->
<!--                                            v-for="(option, id) in allTemplates"-->
<!--                                            :key="id"-->
<!--                                            :value="option.id"-->
<!--                                            :title="option.name || null"-->
<!--                                    >-->
<!--                                        {{option.name}}-->
<!--                                    </option>-->
<!--                                </b-form-select>-->
<!--                                <div class="arrow_down"></div>-->
<!--                            </div>-->
                            <b-button class="send_message-btn" :disabled="!selectedUser.name" @click="sendMail()">Send Email</b-button>
                        </div>
                        <span v-if="gmailError" class="text_danger ml-3">{{gmailError}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "Inbox",
        data() {
            return {
                borderPosition: true,
                chatUsers: [],
                selectedFilter: '',
                filterOptions: [
                    {text: 'All', value: ''},
                    {text: 'Buyer Leads', value: '1'},
                    {text: 'Seller Leads', value: '0'},
                ],
                allTemplates: [
                    {
                        name: 'template 1',
                        id: 1
                    },
                    {
                        name: 'template 2',
                        id: 2
                    }
                ],
                selectedUser: {
                    id: null,
                    email: null,
                    name: null,
                    subject: null,
                    message: null,
                    template_id: null,
                    thread_id: null,
                    message_id: null
                },
                routData: null,
                chats: [],
                userChats: [],
                showMessages: [],
                gmailError: ''
            }
        },
        created() {
            this.getAllLeads()
                .then(res => {
                    if (res.status === 200) {
                        this.chatUsers = res.data.success
                    }
                })
            this.getChats()
                .then(res => {
                    if (res.status === 200) {
                        this.chats = res.data.success
                    }
                })
        },
        computed: {
            ...mapGetters(['userData']),
            filteredLeads() {
                let filteredByType = this.chatUsers.filter((lead) => {
                    return lead.lead_type.toString().includes(this.selectedFilter)
                })
                return filteredByType.sort((a, b) => {
                    return b.name - a.name;
                });
            },
        },
        watch: {
            routData: {
                handler(val) {
                    if (val.query.code) {
                        this.getUrlSuccess(val.query.code)
                            .then(res => {
                                if (res.status === 200) {
                                    // this.$router.push('/inbox')
                                }
                            })
                    }
                },
                deep: true
            }
        },
        mounted() {
            this.routData = this.$route
        },
        methods: {
            ...mapActions(['getAllLeads', 'sendEmail', 'getUrl', 'getUrlSuccess', 'getChats', 'getMessagesById']),
            getDate(item) {
                let date = new Date(item * 1)
                let hours = date.getHours();
                let minutes = date.getMinutes();
                let ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                let strTime = hours + ':' + minutes + ' ' + ampm;
                return strTime;
            },
            scrollToEnd() {
                let container = this.$el.querySelector("#overflow_container");
                container.scrollTop = container.scrollHeight;
            },
            sendMail() {
                this.gmailError = ''
                let messages = []
                messages.push(this.selectedUser)
                this.sendEmail(messages)
                    .then(res => {
                        if (res.status === 200) {
                            if(res.data.url) {
                                this.getRedirectUrl()
                            }
                            this.selectedUser.subject = null
                            this.selectedUser.message = null
                            this.selectedUser.template_id = null
                        } else {
                            this.gmailError = res.response.data.error
                        }
                    })
            },
            getSnippet(text) {
                let item = text.replace(/&#39;/g, "'")
                if(item.includes('On Mon')) return item.split('On Mon')[0]
                else if(item.includes('On Tue')) return item.split('On Tue')[0]
                else if(item.includes('On Wed')) return item.split('On Wed')[0]
                else if(item.includes('On Thu')) return item.split('On Thu')[0]
                else if(item.includes('On Fri')) return item.split('On Fri')[0]
                else if(item.includes('On Sat')) return item.split('On Sat')[0]
                else if(item.includes('On Sun')) return item.split('On Sun')[0]
                else return item
            },
            setEmail(user) {
                // console.log(user, 'ggggggg')
                this.userChats = []
                this.selectedUser.id = user.id
                this.selectedUser.email = user.email
                this.selectedUser.name = user.name
                for (const property in this.chats) {
                    if (this.selectedUser.email === property) {
                        this.userChats = this.chats[property]
                    }
                }
            },
            setMessages(item) {
                // console.log(item, 'item')
                this.selectedUser.thread_id = item.thread_id
                this.selectedUser.message_id =  item.message_id
                this.selectedUser.subject =  item.subject
                this.getMessagesById(item.thread_id)
                    .then(res => {
                        if (res.status === 200) {
                            if (res.data.success.error) {
                                if (res.data.success.error.code === 401 && res.data.success.error.status === "UNAUTHENTICATED") {
                                    this.getRedirectUrl()
                                }
                            } else {
                                this.showMessages = res.data.success.messages
                                setTimeout(() => {
                                    this.scrollToEnd()
                                }, 0)

                            }
                        }
                    })
            },
            getRedirectUrl() {
                this.getUrl()
                    .then(res => {
                        if (res.status === 200) {
                            // const url = window.URL.createObjectURL(new Blob([res.data]));
                            const url = res.data.auth_url
                            const link = document.createElement('a');
                            link.href = url;
                            document.body.appendChild(link);
                            link.click();

                        }
                    })
            },
            viewLeadInfo() {
                this.$router.push(`/leadsProfile/${this.selectedUser.id }`)
            }

        }
    }
</script>

<style scoped lang="scss">
    .page {
        &_container {
            display: flex;
            align-items: center;
            max-width: 1500px;
            width: 100%;
            margin: 0 auto;
            transition: .4s;
            background: #F9FDFF;
            &.straight_length {
                max-width: 1700px;
                transition: .4s;
            }
            &-wrapper {
                display: flex;
                width: 100%;
            }
        }
        &_sidebar {
            width: 350px;
            height: calc(100vh - 150px);
            &-filters {
                height: 56px;
                background: #D7E5EF;
                display: flex;
                align-items: center;
                justify-content: center;
                & .form-group {
                    margin: 0;
                    ::v-deep label {
                        color: $main_blue;
                        font-size: 14px;
                        line-height: 25px;
                    }
                    ::v-deep .bv-no-focus-ring {
                        width: 310px;
                        padding: 0 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                    ::v-deep .custom-control {
                        margin-right: 4px;
                    }
                }
            }
            &-users {
                display: flex;
                flex-direction: column;
                & .user_item {
                    width: 100%;
                    height: 74px;
                    border-bottom: 1px solid #D7E5EF;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    ::v-deep button {
                        width: 100%;
                        height: 74px !important;
                        background: transparent;
                        border: none;
                        display: flex;
                        align-items: center;
                        padding: 5px 20px;
                        &:focus {
                            box-shadow: none;
                        }
                    }
                    &-avatar {
                        width: 38px;
                        height: 42px;
                        background: url("~@/assets/images/user_avatar.svg") no-repeat center;
                        background-size: contain;
                    }
                    &-content {
                        margin-left: 16px;
                        display: flex;
                        flex-direction: column;
                    }
                    &-info {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        min-width: 250px;
                        width: 100%;
                        height: 36px;
                        text-align: start;
                        &_name {
                            color: $black;
                            font-weight: 600;
                        }
                        &_time {
                            color: $grey;
                        }
                    }
                    &-message {
                        color: $grey;
                        font-size: 14px;
                    }
                }
            }
        }
        &_content {
            width: calc(100% - 350px);
        }
        &_head {
            height: 95px;
            border-bottom: 1px solid #DEDEDE;
            display: flex;
            justify-content: space-between;
            padding: 30px;
            &-conversation {
                display: flex;
                align-items: center;
                &_title {
                    font-size: 18px;
                    color: $black;
                }
                &_name {
                    margin-left: 10px;
                    color: $light_blue;
                    font-size: 18px;
                }
            }
            &-dropdown {
                display: flex;
                align-items: center;
                & .auth_btn {
                    padding: 10px 20px;
                    background: $light_blue;
                    border: none;
                    &:focus {
                        background: $light_blue;
                        box-shadow: none;
                        outline: none;
                    }
                }
                &_view {
                    margin: 0 30px;
                    color: $light_blue;
                    text-decoration: underline;
                    text-underline-offset: 3px;
                    cursor: pointer;
                }
                & .select_arrow {
                    position: relative;
                    & .arrow_down {
                        position: absolute;
                        top: 14px;
                        right: 15px;
                        width: 14px;
                        height: 10px;
                        pointer-events: none;
                        background: url("~@/assets/images/arrow_down.svg") right no-repeat;
                    }
                }
                & select {
                    width: 240px;
                    height: 40px;
                    border: 1px solid #798387;
                    background: #fff url("~@/assets/images/arrow_down.svg") right 0.75rem center/10px 10px no-repeat;
                    border-radius: 5px;
                    padding-right: 5px;
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }
        &_text-content {
            padding: 30px 30px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: calc(100vh - 280px);
            & .overflow_container {
                max-height: 390px;
                overflow-y: scroll;
                padding: 0 15px;
            }
            .overflow_container::-webkit-scrollbar {
                width: 5px;
            }
            .overflow_container::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            }
            .overflow_container::-webkit-scrollbar-thumb {
                background-color: darkgrey;
            }
            & .message_item {
                display: flex;
                & .message_text {
                    box-shadow: 0 3px 6px #00000029;
                    border-radius: 10px;
                    background: $white;
                    margin: 10px 30px 10px 0;
                    padding: 20px 40px;
                    & .message_user-info {
                        padding-bottom: 10px;
                        svg {
                            margin-right: 10px;
                        }
                    }
                }
                & .message_date {
                    margin-top: 15px;
                    min-width: 65px;
                }
                &.right_message {
                    flex-direction: row-reverse;
                    & .message_text {
                        background: $light_blue;
                        color: $white;
                        margin: 10px 0px 10px 30px;
                    }
                }
            }
            & .send_message {
                &-container {
                    margin-top: 15px;
                    width: 100%;
                    background: #FFFFFF 0 0 no-repeat padding-box;
                    box-shadow: 0 0 6px #00000029;
                    border-radius: 5px;
                    & input {
                        border: none;
                        &:focus {
                            outline: none;
                            box-shadow: none;
                            border: none;
                        }
                    }
                    & textarea {
                        overflow: auto;
                        border: none;
                        resize: none;
                        &::-webkit-scrollbar {
                            display: none;
                        }
                        &:focus {
                            outline: none !important;
                            border: none !important;
                            box-shadow: none !important;
                        }
                    }
                    & .select_arrow {
                        position: relative;
                        & .arrow_down {
                            position: absolute;
                            top: 14px;
                            right: 15px;
                            width: 14px;
                            height: 10px;
                            pointer-events: none;
                            background: url("~@/assets/images/arrow_down.svg") right no-repeat;
                        }
                    }
                    & select {
                        width: 240px;
                        height: 40px;
                        border: 1px solid #798387;
                        background: #fff url("~@/assets/images/arrow_down.svg") right 0.75rem center/10px 10px no-repeat;
                        border-radius: 5px;
                        padding-right: 5px;
                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                    }
                }
                &-btn {
                    width: 180px;
                    height: 50px;
                    background: $light_blue;
                    color: $white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0 3px 6px #00000029;
                    border-radius: 5px;
                    border: none;
                    &_container {
                        padding: 14px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
</style>